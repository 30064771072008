@import "../../styles/variables";

.map {
  position: relative;
  height: 47.8rem;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  background-color: #dddddd;
}

.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 45.625rem;
  min-height: 20.9rem;
  background-color: #ffffff;
  z-index: 11;
}

.address {
  padding: 2.75rem 3.5rem;
}

.title {
  margin: 0;
  margin-bottom: 2.1rem;
  font-size: 1.625rem;
  font-weight: 900;
  text-transform: uppercase;
}

.text {
  max-width: 14.3rem;
  font-weight: 500;
}

.logoWrap {
  margin-bottom: 2rem;
  max-width: 18.75rem;
}

.logo {
  margin-bottom: 0.4375rem;
  display: block;
}

.feedback {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
  font-size: 0.875rem;
  background-color: #292929;
}

.feedbackTitle {
  margin-bottom: 1.18rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
}

.btn {
  padding: 0.5rem;
  color: #292929;
  font-weight: 500;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0.125rem;
  text-decoration: none;
}

@media (max-width: $tablet-width) {
  .map {
    height: 40rem;
  }

  .content {
    left: 3%;
    grid-template-columns: 1.5fr 1fr;
    width: 35rem;
  }

  .address {
    padding: 1.75rem 2.5rem;
  }
}

@media (max-width: $mobile-width) {
  .content {
    top: 3%;
    right: 3%;
    transform: translateY(0);
    width: auto;
  }
}

@media (max-width: 526px) {
  .map {
    height: 45rem;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .logoWrap {
    margin-bottom: 0.5rem;
  }

  .feedback {
    padding-bottom: 1.125rem;
  }
}