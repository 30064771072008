@import "../../styles/variables";
@import "../../styles/mixins";

.whoFor {
  position: relative;
  max-height: 11rem;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  z-index: 1;
}

.list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: flex-start;
}

.item {
  position: relative;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 11rem;
  width: 100%;
  color: #ffffff;
  //background: linear-gradient(0deg, rgba(18, 27, 37, 0.6), rgba(18, 27, 37, 0.6));
}

.itemActive {
  min-width: 22.5rem;
  min-height: 15rem;
}

.title {
  position: relative;
  margin: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.375rem;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &::after {
    @include pseudo;

    left: 0;
    bottom: 0;
    width: 1.875rem;
    height: 0.125rem;
    background-color: #ffffff;
  }
}

.desc {
  margin: 0;
  display: none;
}

.itemActive .desc {
  display: block;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  z-index: -1;
}

.clickWrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.itemActive .clickWrap {
  cursor: default;
}

@media (max-width: $tablet-width) {
  .whoFor {
    max-height: none;
  }
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .item {
    min-height: 15rem;
  }

  .itemActive {
    min-width: auto;
    min-height: 15rem;
  }
}

@media (max-width: 526px) {
  .list {
    display: grid;
    grid-template-columns: 1fr;
  }
}