@import "../../styles/variables";
@import "../../styles/mixins";

.levels {
  position: relative;
  padding-top: 14.31rem;
  color: #ffffff;

  &::after {
    @include pseudo;

    left: 0;
    bottom: 0;
    width: 100%;
    height: 1308px;
    background-image: url("../../images/formats-back@1x.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  z-index: 2;

  animation-name: levelsWrapAnim;
  animation-play-state: paused;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes levelsWrapAnim {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.levelsWrapAnimate,
.levelsWrapAnimate .tab {
  animation-play-state: running;
}

.slogan {
  position: relative;
  margin-bottom: 4.6rem;
  padding-left: 3.75rem;
  padding-right: 5.5rem;
  display: block;
  max-width: 410px;
  font-size: 3.3125rem;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: 2.7rem;
    left: 0;
    display: block;
    width: 100%;
    height: 1.5rem;
    background-color: #d22323;
    z-index: -1;
    content: "";
  }
}

.tabs {

}

.tabList {
  margin: 0;
  margin-bottom: 5.18rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  transform: scale(0);

  animation-name: tabAnim;
  animation-play-state: paused;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  &:nth-child(2) {
    animation-delay: 1s;
  }

  &:nth-child(3) {
    animation-delay: 1.5s;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: -10px;
    display: block;
    width: 2rem;
    height: 4px;
    background-color: #ffffff;
    content: "";
  }
}

@keyframes tabAnim {
  0% {
    transform: scale(0);
    //opacity: 0;
  }

  100% {
    transform: scale(1);
    //opacity: 1;
  }
}

.tabs :global .react-tabs__tab {
  &--selected {
    color: #d22323;

    &::after {
      background-color: #d22323;
    }
  }
}

.tabIcon {
  margin-bottom: 0.81rem;
}

.tabPanelTitle {
  margin-bottom: 1.875rem;
  font-size: 1.1875rem;
  font-weight: 900;
}

.tabPanelText {
  margin-bottom: 2.25rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.paramList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.paramItem {
  margin-bottom: 1.125rem;
  display: grid;
  grid-template-columns: 3.5rem auto;
  column-gap: 0.9375rem;
}

.paramWrapper {
  position: relative;
  display: grid;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background-color: #ffffff;
  }
}

.paramLabel {
  margin: 0;
  font-size: 1.1875rem;
  font-weight: 500;
  text-transform: uppercase;
}

.bar {
  margin-bottom: 0.25rem;
  display: block;
  align-self: end;
  width: 0;
  height: 0.25rem;
  background-color: #d22323;
}

.recFormatWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
}

@media (max-width: $tablet-width) {
  .levels {
    padding-top: 0;

    &::after {
      height: 135%;
    }
  }

  .wrapper {
    grid-template-columns: 1fr;
  }

  .tabs {
    order: -1;
  }

  .tabList {
    max-width: 31rem;
  }
}

@media (max-width: $mobile-width) {
  .slogan {
    padding: 0;
  }

  .img {
    max-width: 325px;
  }

  .tab {
    font-size: 0.9rem;
  }
}

