@import "src/styles/mixins";

.engraving {
  margin: 0 auto;
  padding: 0 80px;
  padding-top: 40px;

  max-width: 1440px;
}

.inner {
  position: relative;
  padding: 20px;

  display: grid;
  grid-template-columns: 1fr 1.11fr;
  align-items: center;
  gap: 30px;

  background-color: #262f32;
  border-radius: 10px;
}

.img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;

  border-radius: 5px;
}

.content {
  max-width: 520px;

  color: $color-main;
}

.titleWrap {
  margin-bottom: 15px;

  max-width: 370px;
}

.title {
  margin: 0;

  font-size: 60px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  font-family: "Druk Cyr", "Arial", sans-serif;
}

.date {
  margin-left: 8px;

  max-width: 230px;

  font-size: 16px;
}

.link {
  @include btnPrimary;

  max-width: 360px;

  color: $color-main;
  font-size: 12px;

  background-color: #ff3100;

  &:hover {
    background-color: #e03006;
  }
}

.partnerBtn {
  position: absolute;
  top: 12px;
  right: 12px;

  width: 19px;
  height: 19px;

  background-color: transparent;
  background-image: url("../../images/svg/icon-alert.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;

  cursor: pointer;
}

//outside styles
.container {
  padding: 1.5rem;

  display: grid;
  gap: 0.4rem;
  width: 320px;

  color: #000000;
  text-align: center;

  background-color: #ffffff;
  border-radius: 0.6rem;

  & p {
    margin: 0;
  }
}

@media(max-width: $tablet-width) {
  .inner {
    padding: 15px;

    grid-template-columns: 1fr;
  }

  .imgWrap {
    max-width: 572px;
    justify-self: center;
  }
}

@media(max-width: $mobile-width) {
  .engraving {
    padding: 0 20px;
    padding-top: 20px;

    max-width: 320px;
  }

  .inner {
    gap: 21px;
  }

  .imgWrap {
    max-width: 350px;
    justify-self: center;
  }

  .title {
    font-size: 36px;
  }

  .date {
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 0;

    flex-basis: 100%;
  }
}
