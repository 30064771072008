@import "../../styles/variables";
@import "../../styles/mixins";

.registration {
  padding-top: 6.875rem;
  padding-bottom: 20.25rem;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  color: #ffffff;
}

.title {
  position: relative;
  margin: 0 auto;
  margin-bottom: 7.5rem;
  max-width: 37.5rem;
  font-size: 3.3125rem;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: 53%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: 1.5rem;
    background-color: #d22323;
    content: "";
  }
}

.titleWrap {
  position: relative;
  z-index: 1;
}

.list {
  margin: 0;
  margin-bottom: 4.375rem;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
}

.item {
  margin-bottom: 5.93rem;
  width: 440px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
    width: 350px;
  }
}

.itemTitle {
  margin: 0;
  margin-bottom: 1rem;
  min-height: 75px;

  font-size: 1.625rem;
  font-weight: 900;
  text-transform: uppercase;
}

.itemDesc {
  color: #959494;
}

.btn {
  @include btnPrimary;

  margin: 0 auto;
  width: max-content;
}

@media (max-width: $tablet-width) {
  .registration {
    padding-bottom: 8rem;
  }
}

@media (max-width: $mobile-width) {
  .registration {
    padding-top: 4rem;
  }

  .title {
    margin-bottom: 4rem;
    font-size: 2.5rem;

    &::after {
      left: 50%;
      transform: translateX(-50%);
      max-width: 21.875rem;
      height: 0.8rem;
    }
  }

  .item {
    margin-bottom: 2rem;
    width: auto;
    max-width: 21.875rem;
  }
}
