@import "../../styles/variables";
@import "../../styles/mixins";

.topPage {
  position: relative;
  margin-bottom: 7.3rem;
  padding-top: 8.68rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 51rem;
  background-image:
          url("../../images/sky@1x.png");
  background-repeat: no-repeat;
  background-position:
          top center;
  background-size:
          cover;
  overflow: hidden;
}

.logoLiga {
  margin-bottom: 4.12rem;
  z-index: 1;
}

.anchor {
  @include btnPrimary;
  position: relative;
  margin: 0 auto;
  padding: 0.85rem 4rem;
  width: max-content;
  z-index: 2;
  bottom: 2rem;
  position: absolute;
}

.cloud1 {
  position: absolute;
  bottom: -164px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 506px;
  background-image: url("../../images/cloud1@1x.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
}

.cloud {
  position: absolute;
  top: 0;
  display: block;

  animation-play-state: running;
  animation-duration: 12s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.cloud2 {
  left: 81px;
}

.topPageAnimate .cloud2 {
  animation-name: cloud2Anim;
}

.cloud3 {
  right: 36px;
}

.topPageAnimate .cloud3 {
  animation-name: cloud3Anim;
}

@keyframes cloud2Anim {
  0% {
    left: 100%;
  }

  100% {
    left: 10%;
  }
}

@keyframes cloud3Anim {
  0% {
    right: 100%;
  }

  100% {
    right: 10%;
  }
}

.hand {
  position: absolute;
  top: 0;

  animation-play-state: running;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.leftHand {
  left: 0;
}

.topPageAnimate .leftHand {
  animation-name: leftHandAnim;
}

@keyframes leftHandAnim {
  0% {
    transform: translate(-50px, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.rightHand {
  right: 0;
}

.topPageAnimate .rightHand {
  animation-name: rightHandAnim;
}

@keyframes rightHandAnim {
  0% {
    transform: translate(50px, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.legs {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  animation-play-state: running;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.topPageAnimate .legs {
  animation-name: legsAnim;
}

@keyframes legsAnim {
  0% {
    bottom: -50px;
  }

  100% {
    bottom: 0;
  }
}

.landWrap {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  animation-play-state: running;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.topPageAnimate .landWrap {
  animation-name: landAnim;
}

.land {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes landAnim {
  0% {
    bottom: 50px;
    width: 100%;
  }

  100% {
    bottom: -40px;
    width: 110%;
  }
}

@media (min-width: 1300px) {
  .cloud1 {
    bottom: -120px;
  }
}

@media (min-width: 1400px) {
  .cloud1 {
    bottom: -100px;
  }
}

@media (min-width: 1500px) {
  .cloud1 {
    bottom: -70px;
  }
}

@media (max-width: $tablet-width) {
  .topPage {
    min-height: 45rem;
  }

  .cloud2 {
    max-width: 356px;
  }

  .cloud3 {
    max-width: 424px;
  }

  .leftHand {
    max-width: 260px;
  }

  .rightHand {
    max-width: 310px;
  }

  .logoRace {
    max-width: 272px;
    max-height: 138px;
  }

  .legs {
    max-width: 698px;
  }
}

@media (max-width: $mobile-width) {
  .topPage {
    min-height: 37rem;
  }

  .cloud2 {
    max-width: 256px;
  }

  .cloud3 {
    max-width: 324px;
  }

  .leftHand {
    max-width: 140px;
  }

  .rightHand {
    max-width: 190px;
  }

  .logoRace {
    max-width: 222px;
    max-height: 113px;
  }

  .legs {
    max-width: 498px;
  }

  .landWrap {
    position: absolute;
    bottom: 0;
  }
}