@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
  url('./Gilroy-Regular.woff2') format('woff2'),
  url('./Gilroy-Regular.woff') format('woff'),
  url('./Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
  url('Gilroy-SemiBold.woff') format('woff'),
  url('Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
  url('Gilroy-Bold.woff') format('woff'),
  url('Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro'), local('DINPro'),
  url('DINPro.woff') format('woff'),
  url('DINPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
  url('DINPro-CondensedMedium.woff') format('woff'),
  url('DINPro-CondensedMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Medium'), local('DINPro-Medium'),
  url('DINPro-Medium.woff') format('woff'),
  url('DINPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Bold'), local('DINPro-Bold'),
  url('DINPro-Bold.woff') format('woff'),
  url('DINPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Black'), local('DINPro-Black'),
  url('DINPro-Black.woff') format('woff'),
  url('DINPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
  url('DINPro-CondensedBold.woff') format('woff'),
  url('DINPro-CondensedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
  url('DINPro-CondensedBlack.woff') format('woff'),
  url('DINPro-CondensedBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Medium'), local('DrukCyr-Medium'),
  url('DrukCyr-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
