@import "src/styles/mixins";

.tableFormats {
  padding-top: 10.125rem;
  padding-bottom: 10.125rem;
  background-image: url("../../images/table-formats-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  position: relative;
  margin: 0 auto;
  margin-bottom: 5rem;
  display: block;
  max-width: 24.75rem;
  font-size: 3.3125rem;
  text-align: center;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: 2.7rem;
    left: 0;
    display: block;
    width: 100%;
    height: 1.5rem;
    background-color: #d22323;
    content: "";
  }
}

.titleWrap {
  position: relative;
  z-index: 1;
}

.tableWrap {
  overflow: auto;
}

.table {
  width: 100%;
  min-width: 66.875rem;
  border-collapse: collapse;
}

.th {
  padding: 0.5rem;
  transition: opacity 0.25s;
}

.thWrap {
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: #d22323;
  border-radius: 0.25rem;
}

.td {
  padding: 0.5rem;
  width: 18%;

  transition: opacity 0.25s;
}

.tdWrap {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 500;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  background-color: #ffffff;
}

.tdLabel {
  width: 28%;

  .tdWrap {
    background-color: #d2d2d2;
  }
}

.tdOpacity {
  opacity: 0.40;
}

@media (max-width: 98.75rem) {
  .title {
    color: #ffffff;
  }
}

.accordionHead {
  position: relative;
  font-size: 0.875rem;
  font-weight: 900;
  color: #ffffff;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  text-transform: uppercase;
  padding: 0.6rem 1.25rem;
  background-color: #d22323;
  border-radius: 0.25rem;

  &:before {
    @include pseudo;
    top: 50%;
    left: auto;
    right: 1.0625rem;
    transform: translateY(-50%) rotate(-90deg);
    width: 1.0625rem;
    height: 0.8125rem;

    background-image: url("../../images/svg/icon-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.accordionHeadOpen {
  &:before {
    transform: translateY(-50%) rotate(0deg);
  }
}

@media (max-width: $mobile-width) {
  .tableFormats {
    padding-top: 17rem;
  }

  .title {
    margin-bottom: 1.875rem;
    max-width: 17.5rem;
    font-size: 2.25rem;

    &::after {
      top: 1.9rem;

      height: 0.9375rem;
    }
  }

  .table {
    min-width: auto;
  }

  .td {
    padding: 0.3125rem;
    width: 30%;
  }

  .tdLabel {
    width: 70%;
  }

  .tdWrap {
    font-size: 0.5625rem;
  }
}
