@import "../../styles/variables";

.container {
  margin: 0 auto;
  padding: 0 2.8125rem;
  max-width: 75rem;
}

@media (max-width: $mobile-width) {
  .container {
    padding: 0 1.5rem;
  }
}