.citySelect {
  //width: 11.375rem;
}

.citySelect .citySelect__control {
  font-family: 'DIN Pro', 'Arial', sans-serif;
  background-color: transparent;
  text-align: left;
  border-color: #ffffff;
  border-radius: 0.3125rem;
}

.citySelect .citySelect__single-value {
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 500;
}

.citySelect__indicator-separator {
  display: none;
}

.citySelect .citySelect__menu {
  color: black;
}