// Цвета
$color-main: #ffffff;
$color-brand: #d22323;
$color-secondary: #28262e;

$color-focus: #fb4f64;

// Шрифт
$font-family-main: "Panton", sans-serif;

// Брейкпоинты
$desktop-width: 1200px;
$tablet-width: 991px;
$mobile-width: 768px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;