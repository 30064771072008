@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  padding: 1.6rem 1.5rem;
  display: flex;
  align-items: center;
  color: $color-main;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
}

.partnerLogo {
  position: absolute;
  top: 100%;
  right: 8%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 9px 10px rgba(22, 64, 15, 0.6);
}

.logo {
  margin-inline-end: 1rem;
  margin-right: 2.25rem;
}

.navMobile {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.list {
  margin: 0;
  margin-right: auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  list-style: none;
  align-items: center;
}

.item {
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.link {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active {
    opacity: 0.7;
  }
}

.btnWrap {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.headerLink, .btnMenuMobile {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.625rem;
  border: 1px solid #dae0ee;
  background-color: transparent;
  min-height: 3.125rem;
  min-width: 3.125rem;
  cursor: pointer;

  & span {
    margin-left: 0.5rem;
  }

  &:first-child {
    //width: 100%;
    //min-width: 9.1rem;
    //max-width: 9.125rem;
    margin-right: 1rem;
  }
}

.btnMenuMobile {
  display: none;
  position: relative;
  z-index: 99;
  font-size: 0;
  line-height: 0;
  justify-content: center;
  min-width: 3.75rem;
  padding: 1rem 1.125rem;
  margin-left: auto;
}

.gam {
  position: relative;
  width: 1.25rem;
  height: 2px;
  background-color: #dae0ee;
  transition: background-color 0.2s ease;

  &::after, &::before {
    @include pseudo;
    width: inherit; height: inherit;
    background-color: #dae0ee;
    transition: transform 0.2s ease;
  }

  &::after {
    transform: translateY(-0.5rem);
  }

  &::before {
    transform: translateY(0.5rem);
  }
}

// OPEN MENU STATE
.header[data-state="true"] {
  background-color: rgba(0, 0, 0, 0.8);

  & .gam {
    background-color: transparent;

    &::after {
      transform: translateY(0) rotate(-45deg);
    }

    &::before {
      transform: translateY(0) rotate(45deg);
    }
  }
}

.btn {
  @include btnPrimary;

  padding: 1rem 0.5rem;
  max-width: 9.93rem;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dae0ee;
  border-radius: 0.25rem;
}

@media(max-width: $tablet-width) {
  .header {
    padding: 1.66rem 1.5rem;
    z-index: 10;
  }

  .partnerLogo {
    width: 109px;
    height: 72px;
  }

  .btnMenuMobile {
    display: flex;
  }

  .navMobile {
    overflow: auto;
    display: none;
    margin-top: -0.0625rem;
    width: 100%;
    height: calc(100vh - 106px);
    position: absolute;
    top: 106px; left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    align-items: center;
    padding: 3rem 1.5rem 4rem;
    border-bottom-left-radius: 0;
  }

  .list {
    display: block;
    margin: 0 0 2rem;
  }

  .item {
    @include fontStyle(2rem, 1, 600);
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .btnWrap {
    margin-left: 0;
    margin-bottom: 3rem;
    justify-content: space-between;
  }

  .header[data-state="true"] {
    & .navMobile {
      display: flex;
    }
  }

  .btn {
    margin-bottom: 2rem;
  }
}
