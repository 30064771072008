.cityChoiceBack {
  display: flex;
  min-height: 39.9375rem;
  color: #ffffff;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  background-image: url("../../images/city-choice-back@1x.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -2;
}

.wrapper {
  margin: auto;
  margin-top: 6.5rem;
  text-align: center;
}

.title {
  position: relative;
  margin-bottom: 1.1875rem;
  display: block;
  max-width: 19.75rem;
  font-size: 3.3125rem;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: 2.7rem;
    left: 0;
    display: block;
    width: 100%;
    height: 1.5rem;
    background-color: #d22323;
    content: "";
  }
}

.title2 {
  position: relative;
  margin-bottom: 1.1875rem;
  display: block;
  max-width: 19.75rem;
  font-size: 3.3125rem;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
}

.titleWrap {
  position: relative;
  z-index: 1;
}

.desc {
  font-size: 1.1875rem;
  font-weight: 500;
}

.selectWrap {
  //display: grid;
  //grid-template-columns: auto auto;
  //column-gap: 1.25rem;
}

.date {
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0.3125rem;
}