@import "../../styles/variables";
@import "../../styles/mixins";

.faq {
  display: grid;
  grid-template-columns: 13.8rem auto;
  column-gap: 9.93rem;
  font-family: 'DIN Pro', 'Arial', sans-serif;
}

.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab {
  margin-bottom: 2.25rem;
  font-size: 1.4375rem;
  font-weight: 700;
  color: #282828;
  cursor: pointer;
}

.tabActive {
  color: #d22323;
}

.tabPanel {
  @include scrollbar;

  height: 30.3rem;
  display: none;
  overflow-x: auto;
}

.tabPanelActive {
  display: block;
}

.accordionTab {
  margin: 0;
  margin-bottom: 1.62rem;
  font-size: 1.5625rem;
  font-weight: 700;
  cursor: pointer;
}

.accordionPanel {
  display: none;
  font-size: 1.5rem;
}

.accordionPanelActive {
  display: block;
}

@media (max-width: $tablet-width) {
  .faq {
    grid-template-columns: 11rem auto;
    column-gap: 2rem;
  }
}

@media (max-width: $mobile-width) {
  .faq {
    grid-template-columns: 1fr;
    column-gap: 2rem;
  }

  .tabs {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dae0ee;
  }

  .tab {
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 1.3125rem;
  }

  .accordionTab {
    font-size: 1.3125rem;
  }

  .accordionPanel {
    font-size: 1.125rem;
  }
}