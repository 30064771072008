@import "../../styles/variables";
@import "../../styles/mixins";

.descriptions {
  font-family: 'DIN Pro', 'Arial', sans-serif;
}

.descriptionsTop {
  position: relative;
  margin: 0 auto;
  margin-bottom: 12.5rem;
  padding-top: 9rem;
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  max-width: 57.5rem;

  &::after {
    position: absolute;
    top: 0;
    left: 8.125rem;
    right: 0;
    bottom: 0;
    display: block;
    font-size: 9.25rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #f2f2f8;
    content: "Гонка героев";
    z-index: -1;
  }
}

.slogan {
  max-width: 32rem;
  font-size: 4rem;
  font-weight: 900;
  line-height: 0.9;
  text-transform: uppercase;
}

.sloganHero {
  display: inline-block;
  padding-left: 3.4375rem;
  padding-right: 4.375rem;
  margin-bottom: -0.375rem;
  border-bottom: 1.5rem solid $color-brand;
}

.topText {
  position: relative;
  margin: 0;
  font-size: 1.0625rem;
  font-weight: 500;
  text-transform: uppercase;

  &::after {
    position: absolute;
    top: -0.625rem;
    left: 0;
    display: block;
    width: 3.5rem;
    height: 0.5rem;
    background-color: #000000;
    content: "";
  }
}

.blockContainer {
  position: relative;
}

.blockImg {
  position: absolute;
  bottom: 0;
  left: 0;
}

.blockImg {
  animation-play-state: paused;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.blockImg1,
.blockImg3 {
  animation-name: img1Anim;
}

.blockImg2 {
  position: static;
}

.img2Wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.blockImg2 {
  animation-name: img2Anim;
}

@keyframes img1Anim {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes img2Anim {
  0% {
    //right: -100%;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    //right: 0;
  }
}

.content {
  position: relative;
  margin: 0 auto;
  padding: 0 2.8125rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 75rem;
  overflow: hidden;

  &::after {
    position: absolute;
    bottom: 0;
    right: 2.81rem;
    display: block;
    font-size: 19.125rem;
    font-weight: 900;
    line-height: 0.9;
    color: #f2f2f8;
    content: "01";

    animation-play-state: paused;
    animation-duration: 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

.content1 {
  &::after {
    animation-name: number1Anim;
  }
}

.content2 {
  &::after {
    animation-name: number2Anim;
  }
}

.content3 {
  &::after {
    animation-name: number3Anim;
  }
}

@keyframes number1Anim {
  0% {
    right: 0;
  }

  100% {
    right: 2.81rem;
  }
}

@keyframes number2Anim {
  0% {
    left: 0;
  }

  100% {
    left: 2.81rem;
  }
}

@keyframes number3Anim {
  0% {
    left: 59%;
  }

  100% {
    left: 39%;
  }
}

.contentWrapper {
  grid-column: 2 / 3;
  z-index: 1;

  animation-play-state: paused;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.contentWrapper3 {
  padding-top: 50px;
}

.contentWrapper1,
.contentWrapper3 {
  animation-name: contentWrapper1Anim;
}

.contentWrapper2 {
  animation-name: contentWrapper2Anim;
}

@keyframes contentWrapper1Anim {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes contentWrapper2Anim {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0);
  }
}

.blockCont1Animate .blockImg1,
.blockCont1Animate .contentWrapper1,
.blockCont1Animate .content::after{
  animation-play-state: running;
}

.blockCont2Animate .blockImg2,
.blockCont2Animate .contentWrapper2,
.blockCont2Animate .content::after {
  animation-play-state: running;
}

.blockCont3Animate .blockImg3,
.blockCont3Animate .contentWrapper3,
.blockCont3Animate .content::after {
  animation-play-state: running;
}

.title {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 28px;
  font-weight: 900;
  text-transform: uppercase;
}

.text {
  margin-bottom: 2rem;
}

.blockContainer2 {
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  background-color: #f2f2f8;
  //overflow-x: hidden;
}

.blockContainer2 .blockImg {
  left: auto;
  right: 0;
}

.blockContainer2 .contentWrapper {
  grid-column: 1 / 2;
}

.blockContainer2 .content {
  padding-left: 6rem;
  grid-template-columns: 1fr 1.5fr;

  &::after {
    right: auto;
    left: 2.81rem;
    color: #ffffff;
    content: "02";
  }
}

.blockContainer2 .text {
  &:first-child {
    margin-bottom: 3.37rem;
  }
}

.blockContainer3 {
  padding-top: 83px;
  padding-bottom: 54px;
}

.blockContainer3 .blockImg {
  z-index: 1;
}

.blockContainer3 .content {
  margin-bottom: 17.25rem;

  &::after {
    right: auto;
    left: 39%;
    content: "03";
  }
}

.blockContainer3 .text {
  max-width: 21.87rem;
}

.anchor {
  @include btnPrimary;

  position: relative;
  margin: 0 auto;
  padding: 0.85rem 4rem;
  width: max-content;
  z-index: 2;
}

@media (max-width: $tablet-width) {
  .descriptionsTop {
    margin-bottom: 3rem;
    padding-top: 6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    grid-template-columns: 1.2fr 1fr;

    &::after {
      font-size: 7rem;
    }
  }

  .slogan {
    max-width: 400px;
    font-size: 3rem;
  }

  .blockContainer {
    padding-top: 32rem;
  }

  .blockImg {
    top: 0;
    bottom: auto;
  }

  .img2Wrap {
    top: 0;
    bottom: auto;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .blockContainer2 .content {
    padding-left: 6rem;
    grid-template-columns: 1fr;
  }

  .blockContainer3 .content {
    margin-bottom: 2rem;
  }
}

@media (max-width: $mobile-width) {
  .descriptionsTop {
    grid-template-columns: auto;

    &::after {
      left: 3rem;
      max-width: 301px;
      font-size: 4rem;
    }
  }

  .slogan {
    margin-bottom: 2rem;
    max-width: 301px;
    font-size: 2.1rem;
  }

  .topText {
    justify-self: right;
    max-width: 350px;
  }

  .blockContainer {
    padding-top: 22rem;
  }

  .blockImg1,
  .blockImg2 {
    max-width: 375px;
  }

  .blockImg3 {
    max-width: 270px;
  }

  .content {
    padding: 0 1.5rem;
  }

  .blockContainer .content2 {
    padding: 0 1.5rem;
  }

  .blockContainer3 .content {
    &::after {
      right: auto;
      left: 0;
      content: "03";
    }
  }

  @keyframes number3Anim {
    0% {
      left: auto;
      right: 0;
    }

    100% {
      left: auto;
      right: 19%;
    }
  }
}