@import "./variables";

@mixin pseudo {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

@mixin btnPrimary {
  padding: 0.85rem 1.7rem;
  display: block;
  width: 100%;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.0625rem;
  color: $color-main;
  text-align: center;
  text-decoration: none;
  background-color: $color-brand;
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: darken($color-brand, 10%);
  }
}

@mixin fontStyle($font: 1rem, $lh: 1.27, $weight: 400) {
  font-size: $font;
  line-height: $lh;
  font-weight: $weight;
}

@mixin sliderBtn {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: $color-main;

  &::before {
    @include pseudo;

    top: 31%;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    width: 1.75rem;
    height: 2px;
    background-color: #000000;
  }

  &::after {
    @include pseudo;

    bottom: 31%;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1.75rem;
    height: 2px;
    background-color: #000000;
  }
}

@mixin scrollbar {
  // For FireFox
  scrollbar-width: thin;
  scrollbar-color: #000000;

  //For Chrome, Edge, Safari
  &::-webkit-scrollbar {
    width: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border: 0.0625rem solid #e4e4e4;
    border-radius: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 0.125rem;
  }
}