@import "../../styles/variables";
@import "../../styles/mixins";

.formats {
  margin-top: -11.4rem;
  margin-bottom: 2.5rem;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-size: 0.875rem;
}

.list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  gap: 1.875rem;
  list-style: none;
}

.item {
  padding-top: 2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
}

.itemCorp {
  min-height: 28.1rem;
  background-image: url("../../images/corp-back@1x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.title {
  margin: 0;
  margin-bottom: 1.5rem;
  position: relative;
  font-size: 1.125rem;
  font-weight: 900;
  text-transform: uppercase;

  &::after {
    @include pseudo;

    top: calc(100% + 0.2rem);
    left: 0;
    width: 2rem;
    height: 4px;
    background-color: #000000;
  }
}

.desc {
  margin: 0;
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 0.5rem;
  font-weight: 900;
}

.priceWrap {
  margin-bottom: 0;
}

.price {
  display: block;
  font-size: 3rem;
  font-weight: 500;
  color: $color-brand;
}

.quantityTicketsWrap,
.nextPriceWrap {
  font-size: 0.8125rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.quantityTicketsWrap {
  margin-top: 0;
  margin-bottom: 1rem;
}
.quantityTickets {
  font-size: 0.875rem;
  font-weight: 900;
}

.nextPriceWrap {
  margin-top: auto;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: end;
  font-size: 0.875rem;
}

.nextPrice {
  font-size: 1.5rem;
  color: rgba(210, 35, 35, 0.4);
  font-weight: 700;
}

.btn {
  @include btnPrimary;

  margin-top: auto;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
}
