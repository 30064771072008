@import '../../styles/variables';
@import '../../styles/mixins';

.emotions {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  background-image:
          url("../../images/emotions-bg2@1x.png"),
          url("../../images/emotions-bg@1x.jpg");
  background-position: bottom left, bottom 1.625rem center;
  background-repeat: no-repeat;
  background-size: auto, cover;

  animation-name: emotionsAnim;
  animation-play-state: paused;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.emotionsAnimate {
  animation-play-state: running;
}

@keyframes emotionsAnim {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.content {
  padding-top: 2rem;
  padding-right: 3.25rem;
  padding-bottom: 4rem;
  grid-column: 2 / 3;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #ffffff;
}

.title {
  margin-top: 0;
  margin-bottom: 14px;
  font-family: 'DIN Pro', 'Arial', sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.text {
  margin-bottom: 1rem;
}

.btn {
  @include btnPrimary;

  margin: 0 auto;
  margin-bottom: 6.25rem;
  width: max-content;
  font-weight: 500;
}

@media (max-width: $tablet-width) {
  .emotions {
    display: block;
    background-image: url("../../images/emotions-bg@1x.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    padding: 1rem;
  }
}
