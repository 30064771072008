@import "../../styles/mixins";

.gallery {
  margin-bottom: 8.62rem;
}

.sliderWrap {
  position: relative;
}

// Слайдер
.slider {
  height: auto;
}

.slide {
  //padding: 0 1.5rem;
  max-width: 300px;
  height: auto !important;
  min-height: 100%;
  font-size: 0;
}

.sliderNavPrev,
.sliderNavNext {
  @include sliderBtn;

  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;

  &::before {
    top: 40%;
    width: 0.625rem;
  }

  &::after {
    bottom: 40%;
    width: 0.625rem;
  }
}

.sliderNavNext {
  left: auto;
  right: 0;

  &::before {
    transform: translateX(-50%) rotate(45deg);
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg);
  }
}

.item {
  min-height: 138px;
  background-color: #c4c4c4;
}

@media (max-width: $mobile-width) {
  .gallery {
    margin-bottom: 4rem;
  }
}