@import '../../../styles/variables';
@import '../../../styles/mixins';

// Outside styles
.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 67rem;
  width: 95%;
  color: #000000;
  padding: 2.125rem 2.375rem 2.375rem 3.75rem;
  border-radius: 0.625rem;
  background-color: $color-main;
}
// End outside styles

.descBlock {
  width: 75%;
  padding: 0.625rem 0;
  padding-right: 4rem;
  border-right: 1px solid #C4C4C4;
}

.title {
  font-size: 1.375rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  max-width: 90%;
}

.desc {
  font-size: 0.875rem;
  max-width: 37.5rem;
  margin-bottom: 1.5rem;
}

.formWrapper {
  display: flex;
  align-items: center;
}

.cityWrap {
  flex-grow: 1;
}

.cityText {
  position: relative;
  margin-bottom: 1.875rem;
  padding-left: 1.875rem;
  font-size: 0.75rem;
}

.city {
  font-size: 1rem;
  font-weight: 900;

  &::after {
    @include pseudo;

    top: 0;
    left: 0;
    width: 1.125rem;
    height: 1.5rem;
    background-image: url('../../../images/svg/icon-pin.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.inputWrap {
  font-size: 0.75rem;
  gap: 1.25rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.label {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  max-width: 8rem;

  &:first-of-type {
    max-width: 10.75rem;
    display: inline-block;
  }

  &::after, &::before {
    @include pseudo;
  }

  &::after {
    display: none;
    width: 1.125rem;
    height: 1.125rem;
    top: 50%; left: 0.3125rem;
    transform: translateY(-50%);
    border-radius: 0.3125rem;
    background: linear-gradient(180deg, #25B444 0%, #00FF19 100%);
  }

  &::before {
    width: 1.75rem;
    height: 1.75rem;

    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 0.3125rem;
    border: 1px solid #000000;
  }
}

.checkbox:checked + .label::after {
  display: block;
}

.btnWrap {
  max-width: 17.5rem;
  margin-left: 1rem;
  flex-grow: 1;
  margin-top: auto;
}

.btn {
  @include btnPrimary;

  width: 100%;
  border-radius: 1.875rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  min-height: 2.5rem;

  &:disabled {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
  }
}


// COST
.costBlock {
  align-self: flex-end;
  flex-grow: 1;
  padding-left: 2.125rem;
  min-width: 10rem;
}

.counts {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.countBtnsGroup {
  display: flex;
  align-items: center;
}

.countBtn {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.count {
  padding: 0 0.5rem;
}

.worth {
  display: block;
  text-align: right;
}

.worth + .counts {
  margin-top: 0.625rem;
}

.cost {
  font-weight: 900;
}

.goods {
  margin-top: 3rem;
  width: 100%;
}

@media(max-width: $tablet-width) {
  .container {
    width: 40rem;
    display: block;
  }

  .descBlock {
    width: auto;
    margin-bottom: 2rem;
    padding: 0;
    border: none;
  }

  .formWrapper {
    display: block;
  }

  .cityWrap {
    margin-bottom: 1.5rem;
  }

  .btnWrap {
    display: block;
    max-width: none;
    margin: 0;
  }

  .btn {
    &:first-child {
      margin-bottom: 0;
    }
  }

  .costBlock {
    max-width: 30rem;
    padding: 0;
  }

  .worth {
    text-align: left;
  }
}

@media(max-width: $mobile-width) {
  .container {
    width: calc(100% - 3rem);
    padding: 2rem 1.5rem;
  }

  .inputWrap {
    grid-template-columns: 1fr;
  }

  .costBlock {
    min-width: auto;
  }
}
