@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  z-index: 100;
}

.container {
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.3rem;
  height: 3.3rem;
  background-color: $color-brand;
  border: none;
  border-top-right-radius: 0.51rem;
  cursor: pointer;
  z-index: 10;

  &::after, &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #000000;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover,
  &:active {
    background-color: lighten($color-brand, 10%);
  }
}
